@import "~bootstrap/less/bootstrap";
@import "generic";

.error404,
.error500 {
  h2 {
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
  }
}

#maintenance {
  .icon {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 1.6em;
    margin-top: 15px;
    margin-bottom: 50px;
  }
}
